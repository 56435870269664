const projectType = type => {
  switch (type) {
    case "app":
      return "App Development"
    case "seo":
      return "Search Engine Optimization"
    case "ppc":
      return "Pay Per Click"
    default:
      return "Web Design"
  }
}

export default projectType
