import React from "react"

import phoneIcon from "../images/contact/phone.svg"
import emailIcon from "../images/contact/email.svg"
import mapIcon from "../images/contact/map.svg"

const Contact = () => {
  return (
    <div
      className={
        "bg-no-repeat bg-contact bg-contact-p lg:bg-contact-p-lg xl:bg-contact-p-xl bg-contact-s sm:bg-contact-s-sm lg:bg-contact-s-lg xl:bg-contact-s-xl pt-16 pb-24 sm:pt-24 px-8 sm:px-28 lg:px-28 xl:px-60 mx-auto"
      }
      id="contact"
    >
      <div className={"max-w-lg mx-auto"}>
        <h2 className={"text-center pt-2 pb-10"}>
          Let's chat about your next project!
        </h2>
        <form
          className={"flex flex-col"}
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="contact"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <input
            className={
              "border-gray-300 border-1 placeholder-gray-500 rounded p-4 mb-3"
            }
            type="text"
            name="name"
            placeholder="Name"
            required
          />
          <input
            className={
              "border-gray-300 border-1 placeholder-gray-500 rounded p-4 mb-3"
            }
            type="email"
            name="email"
            placeholder="Email"
          />
          <input
            className={
              "border-gray-300 border-1 placeholder-gray-500 rounded p-4 mb-3"
            }
            type="tel"
            name="phone"
            placeholder="Phone Number"
          />
          <textarea
            className={
              "border-gray-300 border-1 placeholder-gray-500 rounded p-4 mb-6"
            }
            name="message"
            placeholder="Message"
            rows="5"
            required
          />
          <button
            className={"bg-pink hover:bg-pink-dark p-4 rounded text-white"}
            type="submit"
          >
            Send
          </button>
        </form>
      </div>
      <div
        className={
          "flex flex-col sm:flex-row items-center justify-between pt-14 sm:pt-24"
        }
      >
        {/* Phone */}
        <div
          className={
            "flex flex-row sm:flex-col lg:flex-row items-center mb-4 sm:mb-0 w-full sm:w-auto"
          }
        >
          <img
            className={"mr-5 sm:mr-0 lg:mr-5 w-14"}
            src={phoneIcon}
            alt="Call Long Island Web Designer"
          />
          <div
            className={
              "text-left sm:text-center lg:text-left pt-0 sm:pt-4 lg:pt-0"
            }
          >
            <h3 className={"text-lg"}>Call/Text Me</h3>
            <span>
              <a className={"text-gray-700"} href="tel:+16317142543">
                631-714-2543
              </a>
            </span>
          </div>
        </div>
        {/* Email */}
        <div
          className={
            "flex flex-row sm:flex-col lg:flex-row items-center mb-4 sm:mb-0 w-full sm:w-auto"
          }
        >
          <img
            className={"mr-5 sm:mr-0 lg:mr-5 w-14"}
            src={emailIcon}
            alt="Email Long Island Web Designer"
          />
          <div
            className={
              "text-left sm:text-center lg:text-left pt-0 sm:pt-4 lg:pt-0"
            }
          >
            <h3 className={"text-lg"}>Email Me</h3>
            <span>
              <a
                className={"text-gray-700"}
                href="mailto:allan@allankiezel.com"
              >
                allan@allankiezel.com
              </a>
            </span>
          </div>
        </div>
        {/* Map */}
        <div
          className={
            "flex flex-row sm:flex-col lg:flex-row items-center w-full sm:w-auto"
          }
        >
          <img
            className={"mr-5 sm:mr-0 lg:mr-5 w-14"}
            src={mapIcon}
            alt="Visit Long Island Web Designer"
          />
          <div
            className={
              "text-left sm:text-center lg:text-left pt-0 sm:pt-4 lg:pt-0"
            }
          >
            <h3 className={"text-lg"}>Visit Me</h3>
            <span className={"text-gray-700"}>Wading River, NY 11792</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
